import React, { useState, useEffect } from 'react'
import HeaderSales from '../common/HeaderSales';
import Footer from '../common/Footer'
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import axios from 'axios';
import * as Constants from "../Constants/index"
import swal from 'sweetalert';
import ResponsivePagination from 'react-responsive-pagination';
import { saveAs } from 'file-saver';
import Oval from '../../loader/CircleLoade';
export default function EnrollkmentReport() {
    // const currentMonth = moment().startOf('month');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [results, setResults] = useState([])
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchRecord, setSearchRecord] = useState()
    const [limit] = useState('10')
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);


    // const dateString = fromDate;
    const formattedDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
    // const dateString2 = toDate;
    const formattedDate2 = moment(toDate).format('YYYY-MM-DD 23:59:59');
    const start = formattedDate.replace(/:/g, '%3A')
    const end = formattedDate2.replace(/:/g, '%3A')


    useEffect(() => {
        let token = localStorage.getItem('token');
        try {
            setLoading(true);
            axios.get(Constants.baseUrl + `reports/user/list?userType=MEMBER&start=${fromDate === "" ? fromDate : start}&end=${toDate === '' ? toDate : end}&sortBy=name&sortAsc=true&limit=${limit}&page=${currentPage}`, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                }
            })
                .then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001 && res.data.output) {
                            setResults(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                            setSearchRecord()
                            setLoading(false);
                        } else if (res.data.code === 12002 && res.data.output) {
                            setLoading(false);
                            setSearchRecord(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })

        } catch (error) {
            console.log(error)
        }

    }, [currentPage])

    const download = () => {
        let token = localStorage.getItem('token');
        try {
            setLoading(true)
            axios.get(Constants.baseUrl + `reports/download/userReport?userType=MEMBER&start=${fromDate === "" ? fromDate : start}&end=${toDate === '' ? toDate : end}`, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'

            })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        const fileData = new Blob([res.data], { type: 'text/csv' });
                        saveAs(fileData, 'enrollmentReport.csv');
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }


    const validateForm = () => {
        let errors = {};

        if (!fromDate) {
            errors.fromDate = 'From Date is required';
        }

        if (!toDate) {
            errors.toDate = 'To Date is required';
        }

        return errors;
    };

    const handleSearch = async () => {

        let token = localStorage.getItem('token');
        setErrors({});
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }
        try {
            setLoading(true)
            await axios.get(Constants.baseUrl + `reports/user/list?userType=MEMBER&start=${start}&end=${end}&sortBy=name&sortAsc=true&limit=${limit}&page=${currentPage}`, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                }
            })
                .then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001) {
                            setLoading(false)
                            setResults(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                            setSearchRecord()
                            window.scrollTo({
                                top: 370,
                                behavior: 'smooth',
                            });
                        } else if (res.data.code === 12002) {
                            setLoading(false)
                            setSearchRecord(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })

        } catch (error) {
            console.log(error)
        }

    }


    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         handleSearch();
    //     }
    // };

    return (
        <>
            {loading ? <Oval /> : null}
            <HeaderSales />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="#Section1" role="tab" data-toggle="tab">Enrollment Report</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>From Date<sup>*</sup> </label>
                                            <div>
                                            </div>
                                            <DatePicker
                                                selected={fromDate}
                                                onChange={(date) => setFromDate(date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                                id="Fromdate"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                            />
                                        </div>
                                        {errors.fromDate && (
                                            <div className="error-message">{errors.fromDate}</div>
                                        )}
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>To Date<sup>*</sup> </label>
                                            <DatePicker
                                                selected={toDate}
                                                onChange={(date) => setToDate(date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                                id="todate"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                            />
                                        </div>
                                        {errors.toDate && (
                                            <div className="error-message">{errors.toDate}</div>
                                        )}
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                        <div className="form-group has-float-label style-two">
                                            <label className="invisible" style={{ width: "100%" }}>hidden</label>
                                            <input type="button" className="btn login_btn" value="Search" id="btnSearch" onClick={handleSearch} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-header card-header-warning">
                                                <h4 className="card-title"> Enrollment Report
                                                    {searchRecord === null ? '' :
                                                        <Link to="#" class="pull-right" onClick={download}><i class="fa fa-file-excel-o"></i> Export</Link>}
                                                </h4>
                                            </div>

                                            <div className="card-body table-responsive">
                                                <table className="table table-striped mb_01" id="table-to-xls">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Zonal Head	</th>
                                                            <th>State Head	</th>
                                                            <th>Sales Team	</th>
                                                            <th>Plumber Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Email ID</th>
                                                            {/* <th>DOB	</th> */}
                                                            <th>Mobile No. Linked with Aadhar</th>
                                                            <th>Aadhar Front Image</th>
                                                            <th>Aadhar back Image</th>
                                                            <th>Address1	</th>
                                                            <th>Address2	</th>
                                                            <th>Address3</th>
                                                            <th>City	</th>
                                                            <th>State	</th>
                                                            <th>Date of Registartion</th>
                                                            <th>Registration Type</th>
                                                            <th>Status</th>
                                                            <th>Reason for Rejection</th>
                                                            <th>Admin Approved/Rejected Date</th>
                                                            <th>Admin Approved/Rejected By</th>
                                                            <th>Sales/Marketing Team Name</th>
                                                            <th>Sales/Marketing Team Approval Date</th>
                                                            {/* <th>Bonous</th> */}
                                                            <th>Total Earned Stars	</th>
                                                            <th>Total Redeemed Stars</th>
                                                            <th>First Scan Date</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                const dateOfRegistration = moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                                                const actiondate = item.approval ? moment(item.approval?.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY') : ''
                                                                const salesApprovalDate = item.approval ? moment(item.approval?.updateDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY') : ''
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                const isAadharFrontUrlAvailable = item.userModelKYC?.aadharFrontUrl && item.userModelKYC?.aadharFrontUrl.trim() !== "";
                                                                const isAadharBackUrlAvailable = item.userModelKYC?.aadharBackUrl && item.userModelKYC?.aadharBackUrl.trim() !== "";
                                                                const firstScanDate = item.firstScan ? moment(item.firstScan).format('DD-MM-YYYY') : ''
                                                                return (
                                                                    <>
                                                                        <tr key={index}>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item.roName}</td>
                                                                            <td>{item.shName}</td>
                                                                            <td>{item.parentName}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.mobile}</td>
                                                                            <td>{item.email}</td>
                                                                            {/* <td>{item.dob}</td> */}
                                                                            <td>{item.userModelKYC?.aadharNumber}</td>
                                                                            <td>{isAadharFrontUrlAvailable ? (
                                                                                <Link to={item.userModelKYC?.aadharFrontUrl} target="_blank" className="select">View</Link>) : (
                                                                                "N/A"
                                                                            )}</td>
                                                                            <td>{isAadharBackUrlAvailable ? (
                                                                                <Link to={item.userModelKYC?.aadharBackUrl} target="_blank" className="select">View</Link>) : (
                                                                                "N/A"
                                                                            )}</td>
                                                                            <td>{item.addressWork?.workAddress1}</td>
                                                                            <td>{item.addressWork?.workAddress2}</td>
                                                                            <td>{item.addressWork?.workAddress3}</td>
                                                                            <td>{item.addressWork?.workCity}</td>
                                                                            <td>{item.addressWork?.workState}</td>
                                                                            <td>{dateOfRegistration}</td>
                                                                            <td>{item.registerFrom}</td>
                                                                            <td>{item.status}</td>
                                                                            <td>{item.approval?.reason}</td>
                                                                            <td>{actiondate}</td>
                                                                            <td>{item.approval?.actionBy?.name}</td>
                                                                            <td>{item?.parentName}</td>
                                                                            <td>{salesApprovalDate}</td>
                                                                            {/* <td></td> */}
                                                                            <td>{item.totalPoints}</td>
                                                                            <td>{item.redeemPoints}</td>
                                                                            <td>{firstScanDate}</td>

                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
