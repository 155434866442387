import React, { useState, useEffect } from 'react'
import Footer from '../common/Footer'
import { Link } from 'react-router-dom'
import HeaderSales from '../common/HeaderSales';
import axios from 'axios';
import * as Constants from "../Constants/index"
import swal from 'sweetalert';
import { saveAs } from 'file-saver';
import moment from 'moment';
import ResponsivePagination from 'react-responsive-pagination';
import Oval from '../../loader/CircleLoade';
export default function SalesErrorQrTransactionReport() {
    const [searchQuery, setSearchQuery] = useState('')
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState('10')
    const [results, setResults] = useState([])
    const [searchRecord, setSearchRecord] = useState()
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        errorQrReport(searchQuery)


    }, [currentPage])


    const errorQrReport = (userName) => {
        let token = localStorage.getItem('token');
        try {
            const searchUserVal = userName === '' ? '' : `mobile=${userName}&`
            setLoading(true)
            axios.get(Constants.baseUrl + `reports/errorQrTrans/list?${searchUserVal}userType=MEMBER&sortBy=createDateTime&sortAsc=true&limit=${limit}&page=${currentPage}`, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                }
            })
                .then((res) => {
                    if (res.status === 201) {
                        if (res.data.code === 10001) {
                            setResults(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                            setSearchRecord()
                            setLoading(false)
                        } else if (res.data.code === 12002) {
                            setSearchRecord(res.data.output.results)
                            setTotalPages(res.data.output.totalPages);
                            setLoading(false)
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })

        } catch (error) {
            console.log(error)
        }
    }

    const download = () => {
        let token = localStorage.getItem('token');
        try {
            const searchUserVal = searchQuery === '' ? '' : `mobile=${searchQuery}&`
            axios.get(Constants.baseUrl + `reports/download/errorQrTransreport?${searchUserVal}userType=MEMBER`, {
                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'

            })
                .then((res) => {
                    if (res.status === 200) {

                        const fileData = new Blob([res.data], { type: 'text/csv' });
                        saveAs(fileData, 'errorQrTransactionReports.csv');
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const handleSearchQuery = (event) => {
        // setSearchQuery(event.target.value)
        const input = event.target.value;
        if (/^\d*$/.test(input)) {
            setSearchQuery(input);
        }
        if (event.target.value === '') {
            errorQrReport('')
            setSearchRecord()
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            errorQrReport(searchQuery)
        }
    };

    return (
        <>
            {loading ? <Oval /> : null}
            <HeaderSales />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><Link to="#Section1" role="tab" data-toggle="tab">Error QR Transaction  Report</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15">


                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label>Mobile No.<sup>*</sup> </label>
                                            <input className="form-control" type="text" maxLength='10' value={searchQuery} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="form-group has-float-label style-two">
                                            <label className="invisible" style={{ width: "100%" }}>hidden</label>
                                            <input type="button" className="btn login_btn" value="Search" id="btnSearch" disabled={searchQuery.trim() === ''} onClick={() => errorQrReport(searchQuery)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="carrd1">
                                            <div className="card-header card-header-warning">
                                                <h4 className="card-title">Error QR Transaction Report
                                                    {searchRecord === null ? '' :
                                                        <Link to="#" class="pull-right" onClick={download}><i class="fa fa-file-excel-o"></i> Export</Link>}
                                                </h4>
                                            </div>

                                            <div className="card-body table-responsive">

                                                <table className="table table-striped mb_01" id="table-to-xls">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Scan ID	</th>
                                                            <th>Member Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>DOJ	</th>
                                                            <th>State</th>
                                                            <th>City</th>
                                                            <th>Scan Date</th>
                                                            <th>Status	</th>
                                                            {/* <th>Product Category</th>
                                                            <th>Product SubCategory	</th>
                                                            <th>SAP Code	</th> */}
                                                            <th>QR Details</th>
                                                            <th>Base Points</th>
                                                            <th>Balance Points</th>
                                                            <th>Remark</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="13" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> : <tbody>
                                                            {results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                const formattedDate = moment(item.user.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item.id}</td>
                                                                            <td>{item.user.name}</td>
                                                                            <td>{item.user.mobile}</td>
                                                                            <td>{formattedDate}</td>
                                                                            <td>{item.user.addressWork.workState}</td>
                                                                            <td>{item.user.addressWork.workCity}</td>
                                                                            <td>{item.createDateTime}</td>
                                                                            <td>{item.status}</td>
                                                                            {/* <td></td>
                                                                            <td></td>
                                                                            <td></td> */}
                                                                            <td>{item.code}</td>
                                                                            <td>{item.pointsEarned}</td>
                                                                            <td>{item.user.points}</td>
                                                                            <td>{item.reason}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
