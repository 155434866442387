import React, { useState, useEffect } from 'react'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import axios from 'axios';
import * as Constants from "./Constants/index"
// import swal from 'sweetalert';

export default function BarChart() {
    const [chartInitialized, setChartInitialized] = useState(false);
    // const [data, setData] = useState([])
    // const [category, setCategory] = useState('')
    // const [totalScan, setTotalScan] = useState('')


    // useEffect(() => {
    //     let token = localStorage.getItem('token');

    //     try {
    //         const res = axios.post(Constants.baseUrl + `capture/qr/product_report_trend`, {}, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
    //                 appVersion: Constants.av,
    //                 platform: Constants.pt,
    //                 program: Constants.program,
    //                 company: Constants.company
    //             }
    //         })
    //             .then((res) => {
    //                 if (res.status == 201) {
    //                     if (res.data.code === 10001) {
    //                         setData(res.data.output)
    //                     }
    //                 } else {
    //                     swal(res.data.msg);
    //                 }
    //             })
    //     } catch (error) {
    //         console.log(error)
    //     }


    // }, [])

    useEffect(() => {
        if (!chartInitialized) {

            am5.ready(function () {

                // Create root element
                var root = am5.Root.new("chartdiv3");

                // Set themes
                root.setThemes([
                    am5themes_Animated.new(root)
                ]);

                // Create chart
                var chart = root.container.children.push(am5xy.XYChart.new(root, {
                    panX: true,
                    panY: true,
                }));

                // Add cursor
                var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineY.set("visible", false);
                setChartInitialized(true);


                // Create axes
                var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
                xRenderer.labels.template.setAll({
                    rotation: 0,
                    centerY: am5.p50,
                    centerX: am5.p100,
                    paddingRight: -20
                });

                xRenderer.grid.template.setAll({
                    location: 1
                });

                var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                    maxDeviation: 0.3,
                    categoryField: "product",
                    renderer: xRenderer,
                    tooltip: am5.Tooltip.new(root, {})
                }));

                var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                    maxDeviation: 0.3,
                    renderer: am5xy.AxisRendererY.new(root, {
                        strokeOpacity: 0.7,

                    })
                }));

                // Create series
                var series = chart.series.push(am5xy.ColumnSeries.new(root, {
                    name: "Series 1",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "quantity",
                    sequencedInterpolation: true,
                    categoryXField: "product",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueY}"
                    })
                }));

                series.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0, strokeOpacity: 0 });

                // Set data

                const fetchData = async () => {
                    let token = localStorage.getItem('token');
                    try {
                        const response = await axios.post(Constants.baseUrl + `capture/qr/product_report_trend`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                                appVersion: Constants.av,
                                platform: Constants.pt,
                                program: Constants.program,
                                company: Constants.company
                            }
                        });
                        const apiData = response.data.output;

                        // Process API data and set it to the chart
                        var data = apiData.map(item => ({
                            product: item.category,
                            quantity: item.total_scan
                        }));

                        xAxis.data.setAll(data);
                        series.data.setAll(data);
                    } catch (error) {
                        console.log(error);
                    }
                };
                fetchData();


                // var data = [
                //     { product: "Cistern", quantity: 2637 },
                // ];

                // xAxis.data.setAll(data);
                // series.data.setAll(data);

                // Set column colors
                series.columns.template.setAll({ fill: am5.color("#90c4e2") }); // Set the desired color here

                // Make stuff animate on load
                series.appear(1000);
                chart.appear(1000, 100);
                return () => {
                    root.dispose();
                };
            });
        }
    }, [chartInitialized]);


    return (
        <>
            <div className="col-md-5 col-sm-12 col-xs-12 col40">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title text-center">
                            Product Category Performance
                        </h3>
                    </div>
                    <div className="card-body">
                        <div id="chartdiv3">
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
