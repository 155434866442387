import React, { useState, useEffect } from 'react'
import Footer from '../common/Footer'
import { Link } from 'react-router-dom'
import HeaderSales from '../common/HeaderSales';
import axios from 'axios';
import * as Constants from "../Constants/index"
import { saveAs } from 'file-saver';
import swal from 'sweetalert';
import ResponsivePagination from 'react-responsive-pagination';
import moment from 'moment';
import Oval from '../../loader/CircleLoade';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
export default function SalesQrTransactionReport() {
    const navigate = useNavigate()
    const [searchUser, setSearchUser] = useState('')
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10)
    const [errors, setErrors] = useState({});
    const [results, setResults] = useState([])
    const [searchRecord, setSearchRecord] = useState()
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const formattedDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
    const formattedDate2 = moment(toDate).format('YYYY-MM-DD 23:59:59');
    const start = formattedDate.replace(/:/g, '%3A')
    const end = formattedDate2.replace(/:/g, '%3A')


    useEffect(() => {
        if (currentPage !== 1) {
            qrTransReport(searchUser);
        } else {
            qrTransReport('');
        }

    }, [currentPage])

    const qrTransReport = (userName) => {
        let token = localStorage.getItem('token');
        if (!token) {
            navigate('/')
        } else {
            const searchUser = userName === '' ? '' : `mobile=${userName}&`
            const searchFromDate = fromDate === '' ? '' : `start=${start}&`
            const searchToDate = toDate === '' ? '' : `end=${end}&`
            if (fromDate !== '' && toDate === '') {
                swal({
                    text: 'Please select to date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if reason is not selected when status is 'reject'
            }
            if (fromDate === '' && toDate !== '') {
                swal({
                    text: 'Please select from date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if reason is not selected when status is 'reject'
            }
            if (fromDate !== '' && toDate !== '' && new Date(fromDate) > new Date(toDate)) {
                swal({
                    text: 'From date should be less than to date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if "from" date is greater than or equal to "to" date
            }
            try {
                setLoading(true);
                axios.get(Constants.baseUrl + `reports/qrTrans/list?${searchUser}${searchFromDate}${searchToDate}userType=MEMBER&limit=${limit}&page=${currentPage}`, {

                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    }
                })
                    .then((res) => {
                        if (res.status === 201) {
                            if (res.data.code === 10001 && res.data.output) {
                                setResults(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                                setSearchRecord()
                                setLoading(false);
                            } else if (res.data.code === 12002) {
                                setLoading(false);
                                setSearchRecord(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                            } else {
                                swal({
                                    text: res.data.msg,
                                    icon: 'error',
                                    timer: 2000,
                                    buttons: false,
                                })
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }
    }
    const handleSearchQuery = (event) => {
        const input = event.target.value;
        if (/^\d*$/.test(input)) {
            setSearchUser(input);
        }
        if (event.target.value === '') {
            qrTransReport('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            qrTransReport(searchUser);
        }
    };


    const download = () => {
        let token = localStorage.getItem('token');
        try {
            const searchUserVal = searchUser === '' ? '' : `mobile=${searchUser}&`
            const searchFromDate = fromDate === '' ? '' : `start=${start}&`
            const searchToDate = toDate === '' ? '' : `end=${end}&`
            if (fromDate !== '' && toDate === '') {
                swal({
                    text: 'Please select to date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if reason is not selected when status is 'reject'
            }
            if (fromDate === '' && toDate !== '') {
                swal({
                    text: 'Please select from date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if reason is not selected when status is 'reject'
            }
            if (fromDate !== '' && toDate !== '' && new Date(fromDate) > new Date(toDate)) {
                swal({
                    text: 'From date should be less than to date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if "from" date is greater than or equal to "to" date
            }
            setLoading(true)
            axios.get(Constants.baseUrl + `reports/download/qrTransreport?${searchUserVal}${searchFromDate}${searchToDate}userType=MEMBER`, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'

            })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        const fileData = new Blob([res.data], { type: 'text/csv' });
                        saveAs(fileData, 'qrTransactionReports.csv');
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {loading ? <Oval /> : null}
            <HeaderSales />
            <section class="blog-area pt182">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 tab mb-20">
                            <ul class="nav nav-tabs" role="tablist">
                                <li role="presentation" class="active"><Link to="#Section1" role="tab" data-toggle="tab">QR Transaction  Report</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="all-single-blog rpt">
                                <div class="row clearfix mb-15">
                                    <div class="col-md-3 col-sm-12 col-xs-12">
                                        <div class="form-group has-float-label style-two">
                                            <label>Mobile No.<sup>*</sup> </label>
                                            <input className="form-control" placeholder="Mobile no." type="text" maxLength='10' value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-12 col-xs-12">
                                        <div class="form-group has-float-label style-two">
                                            <label>From Date<sup>*</sup> </label>
                                            <DatePicker
                                                selected={fromDate}
                                                onChange={(date) => setFromDate(date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                                id="Fromdate"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                            />
                                        </div>
                                        {errors.fromDate && (
                                            <div className="error-message">{errors.fromDate}</div>
                                        )}
                                    </div>
                                    <div class="col-md-3 col-sm-12 col-xs-12">
                                        <div class="form-group has-float-label style-two">
                                            <label>To Date<sup>*</sup> </label>
                                            <DatePicker
                                                selected={toDate}
                                                onChange={(date) => setToDate(date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                                id="todate"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                            />
                                        </div>
                                        {errors.toDate && (
                                            <div className="error-message">{errors.toDate}</div>
                                        )}
                                    </div>
                                    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                                        <div class="form-group has-float-label style-two">
                                            <label class="invisible" style={{ width: "100%" }}>hidden</label>
                                            <input type="button" class="btn login_btn" value="Search" id="btnSearch" onClick={() => qrTransReport(searchUser)} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="carrd1">
                                            <div class="card-header card-header-warning">
                                                <h4 class="card-title">QR Transaction Report
                                                    {searchRecord === null ? '' :
                                                        <Link to="#" class="pull-right" onClick={download}><i class="fa fa-file-excel-o"></i> Export</Link>}
                                                </h4>
                                            </div>
                                            <div class="card-body table-responsive">

                                                <table class="table table-striped mb_01" id="table-to-xls">
                                                    <thead>
                                                        <tr>
                                                            <th>S.no</th>
                                                            <th>Member name</th>
                                                            <th>Mobile Number</th>
                                                            <th>DOJ	</th>
                                                            <th>State	</th>
                                                            <th>City	</th>
                                                            <th>Address	Registred By Sales/Marketing Name</th>
                                                            <th>Approved by Sales/Marketing Name</th>
                                                            <th>Date of Scan</th>
                                                            <th>Scan ID	</th>
                                                            <th>Product Category</th>
                                                            <th>Product SubCategory</th>
                                                            <th>SAP Code	</th>
                                                            <th>QR Details	</th>
                                                            {/* <th>ManufacturingDate	</th> */}
                                                            {/* <th>Dealer Number</th> */}
                                                            {/* <th>Dealer Name	</th> */}
                                                            <th>TransactionTime	</th>
                                                            <th>Base Stars</th>
                                                            <th>Stars	</th>
                                                            <th>Total Stars</th>
                                                            <th>Status</th>
                                                            <th>Remark</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                const scanDate = moment(item.scanDateTime).format('DD-MM-YYYY')
                                                                const doj = moment(item.user.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY')
                                                                // const transTime = moment(item.createDateTime).format('DD-MM-YYYY')
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item.user.name}</td>
                                                                            <td>{item.user.mobile}</td>
                                                                            <td>{doj}</td>
                                                                            <td>{item.user.addressWork?.workState}</td>
                                                                            <td>{item.user.addressWork?.workCity}</td>
                                                                            <td>{item.user.addressWork?.workAddress1}</td>
                                                                            <td>{item.user?.parentName}</td>
                                                                            <td>{scanDate}</td>
                                                                            <td>{item.id}</td>
                                                                            <td>{item.programProduct?.category}</td>
                                                                            <td>{item.programProduct?.subCategory}</td>
                                                                            <td>{item.programProduct?.sapCode}</td>
                                                                            <td>{item.code}</td>
                                                                            {/* <td>{item.manufacturingDate}</td> */}
                                                                            {/* <td>{item.delearNumber}</td> */}
                                                                            {/* <td>{item.delearName}</td> */}
                                                                            <td>{item.createDateTime}</td>
                                                                            <td>{item.pointsEarned}</td>
                                                                            <td>{item.user?.points}</td>
                                                                            <td>{item.user?.totalPoints}</td>
                                                                            <td>{item.status}</td>
                                                                            <td>{item.reason}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}

                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
